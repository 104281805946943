/**
 * @generated SignedSource<<46fea7d25e57c70faa9db46c53780e28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyMobileRefineMenu_filters$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDisplayPrefsSelect_filters" | "SbSharedRefineMenuFilters_filters">;
  readonly " $fragmentType": "BuyMobileRefineMenu_filters";
}>;
export type BuyMobileRefineMenu_filters$key = ReadonlyArray<{
  readonly " $data"?: BuyMobileRefineMenu_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyMobileRefineMenu_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BuyMobileRefineMenu_filters",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_filters"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDisplayPrefsSelect_filters"
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "6676c7184e73b1b052f6849c86a0639b";

export default node;
