import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Swiper } from 'dibs-pinch-zoom-swipe';
import { BuySharedCategoryTile } from './BuySharedCategoryTile';
import { FullPageWidth } from '../../components/global/FullPageWidth/FullPageWidth';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';
import { BuySharedCategoryTilesTracking } from './BuySharedCategoryTilesTracking';

import styles from './BuyMobileCategoryTiles.scss';

import { type BuyMobileCategoryTiles_itemSearch$key as ItemSearch } from './__generated__/BuyMobileCategoryTiles_itemSearch.graphql';

type Props = {
    itemSearch: ItemSearch;
};

export const BuyMobileCategoryTiles: FC<Props> = ({ itemSearch: itemSearchRef }) => {
    const itemSearch = useFragment(
        graphql`
            fragment BuyMobileCategoryTiles_itemSearch on ItemSearchQueryConnection {
                categoryTiles {
                    rank
                    ...BuySharedCategoryTile_categoryTile
                }
                ...BuySharedCategoryTilesTracking_itemSearch
            }
        `,
        itemSearchRef
    );

    const { categoryTiles } = itemSearch;

    if (!categoryTiles || !categoryTiles.length) {
        return null;
    }

    const swiperClassNames = {
        item: styles.item,
        list: styles.list,
        wrapper: styles.wrapper,
    };

    const tileClassNames = {
        tileStyle: styles.tile,
        imageStyle: styles.image,
        titleStyle: styles.title,
    };

    return (
        <CurrencyProvider
            render={({ currency }: { currency: string }) => (
                <BuySharedCategoryTilesTracking itemSearch={itemSearch}>
                    {({ trackClick }) => (
                        <FullPageWidth>
                            <Swiper itemsPerPage={2.5} classNames={swiperClassNames}>
                                {categoryTiles.map(categoryTile => (
                                    <BuySharedCategoryTile
                                        classNames={tileClassNames}
                                        categoryTile={categoryTile}
                                        currency={currency}
                                        trackClick={trackClick}
                                        key={categoryTile?.rank}
                                    />
                                ))}
                            </Swiper>
                        </FullPageWidth>
                    )}
                </BuySharedCategoryTilesTracking>
            )}
        />
    );
};
