/**
 * @generated SignedSource<<aacc07d82470bab7f97d28755c9d9c4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyMobileRefineMenu_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDisplayPrefsSelect_user" | "SbSharedRefineMenuFilters_user">;
  readonly " $fragmentType": "BuyMobileRefineMenu_user";
};
export type BuyMobileRefineMenu_user$key = {
  readonly " $data"?: BuyMobileRefineMenu_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyMobileRefineMenu_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuyMobileRefineMenu_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDisplayPrefsSelect_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4b7d9cb6e68713a9a4bfb4bd2f565a2a";

export default node;
