/**
 * @generated SignedSource<<dfe73e2831e1d1a11d431b3f575c0649>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyMobileRefineMenu_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilters_viewer">;
  readonly " $fragmentType": "BuyMobileRefineMenu_viewer";
};
export type BuyMobileRefineMenu_viewer$key = {
  readonly " $data"?: BuyMobileRefineMenu_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyMobileRefineMenu_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuyMobileRefineMenu_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "d84bddee47217c8da2166ea0e9414a8e";

export default node;
