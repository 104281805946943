/**
 * @generated SignedSource<<46ee141756fd9c8150e04242afacb644>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyMobileLayout_viewer$data = {
  readonly buyPage: {
    readonly appliedFilters: ReadonlyArray<{
      readonly name: string | null;
      readonly values: ReadonlyArray<{
        readonly displayName: string | null;
      } | null> | null;
    } | null> | null;
    readonly bottomModule: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"SharedBottomModule_bottomModule">;
    } | null> | null;
    readonly displayMaxNumberOfPages: number | null;
    readonly displayUriRef: string | null;
    readonly filters: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"BuyMobileRefineMenu_filters">;
    } | null> | null;
    readonly internalBuyLinks: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"SharedMobileLinksBlock_links">;
    } | null> | null;
    readonly meta: {
      readonly buyPageAvailableItemCount: number | null;
    } | null;
    readonly totalResults: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"BuyMobileCategoryTiles_itemSearch" | "BuyMobileRefineMenu_buyPage" | "BuySharedBreadcrumbs_buyPage" | "BuySharedCMSFAQ_buyPage" | "BuySharedDescription_buyPage" | "BuySharedHead_buyPage" | "BuySharedPersonalizedSearchItems_itemSearch" | "BuySharedRefetchContainer_buyPage" | "BuySharedRelatedPages_buyPage" | "BuySharedResultContainer_buyPage" | "BuySharedTitle_buyPage" | "SbMobileAppliedFilters_itemSearch" | "SharedFAQ_itemSearch" | "SharedFollowSearchBanner_itemSearch" | "SharedMobileLinksBlock_itemSearch" | "SharedRecentSales_itemSearch" | "SharedSaveSearchContext_itemSearch" | "SharedWordpressArticles_itemSearch" | "SharedYMAL_itemSearch">;
  } | null;
  readonly isNlgContentEnabled: boolean | null;
  readonly isRelatedBuyPagesEnabled: boolean | null;
  readonly isWordpressArticlesEnabled: boolean | null;
  readonly user?: {
    readonly " $fragmentSpreads": FragmentRefs<"BuyMobileRefineMenu_user" | "BuySharedPersonalizedSearchItems_user" | "BuySharedResultContainer_user" | "SbMobileAppliedFilters_user" | "SharedFollowSearchBanner_user" | "SharedRecentSales_user" | "SharedSaveSearchContext_user" | "SharedYMAL_user">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"BuyMobileRefineMenu_viewer" | "BuySharedPersonalizedSearchItems_viewer" | "BuySharedRefetchContainer_viewer" | "BuySharedResultContainer_viewer" | "SharedMobileLinksBlock_viewer" | "SharedRecentSales_viewer">;
  readonly " $fragmentType": "BuyMobileLayout_viewer";
};
export type BuyMobileLayout_viewer$key = {
  readonly " $data"?: BuyMobileLayout_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyMobileLayout_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "priceBookName",
  "variableName": "priceBookName"
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fetchUser"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "guestId"
    },
    {
      "kind": "RootArgument",
      "name": "localeOrigin"
    },
    {
      "kind": "RootArgument",
      "name": "page"
    },
    {
      "kind": "RootArgument",
      "name": "personalizationId"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    },
    {
      "kind": "RootArgument",
      "name": "regionsList"
    },
    {
      "kind": "RootArgument",
      "name": "rootId"
    },
    {
      "kind": "RootArgument",
      "name": "uriRef"
    },
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuyMobileLayout_viewer",
  "selections": [
    {
      "alias": "isWordpressArticlesEnabled",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "WP_EDITORIAL"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"WP_EDITORIAL\")"
    },
    {
      "alias": "isNlgContentEnabled",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "NLG_CONTENT"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"NLG_CONTENT\")"
    },
    {
      "alias": "isRelatedBuyPagesEnabled",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "RELATED_BUY_PAGES"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"RELATED_BUY_PAGES\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BuySharedRefetchContainer_viewer"
    },
    {
      "alias": "buyPage",
      "args": [
        {
          "kind": "Variable",
          "name": "buyPageId",
          "variableName": "rootId"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "guestId",
          "variableName": "guestId"
        },
        {
          "kind": "Variable",
          "name": "localeOrigin",
          "variableName": "localeOrigin"
        },
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        },
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "regionsList",
          "variableName": "regionsList"
        },
        {
          "kind": "Variable",
          "name": "uriRef",
          "variableName": "uriRef"
        },
        {
          "kind": "Variable",
          "name": "userCountryCode",
          "variableName": "userCountryCode"
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "personalizationId"
        }
      ],
      "concreteType": "ItemSearchQueryConnection",
      "kind": "LinkedField",
      "name": "itemSearch",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalResults",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayMaxNumberOfPages",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayUriRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParam",
          "kind": "LinkedField",
          "name": "appliedFilters",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SearchBrowseParsedParamValue",
              "kind": "LinkedField",
              "name": "values",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MetaData",
          "kind": "LinkedField",
          "name": "meta",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buyPageAvailableItemCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseFilter",
          "kind": "LinkedField",
          "name": "filters",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BuyMobileRefineMenu_filters"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BottomModule",
          "kind": "LinkedField",
          "name": "bottomModule",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SharedBottomModule_bottomModule"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuySharedRefetchContainer_buyPage"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuySharedBreadcrumbs_buyPage"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuySharedTitle_buyPage"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuySharedRelatedPages_buyPage"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuySharedResultContainer_buyPage"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedRecentSales_itemSearch"
        },
        {
          "args": (v1/*: any*/),
          "kind": "FragmentSpread",
          "name": "SharedYMAL_itemSearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuySharedDescription_buyPage"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuySharedHead_buyPage"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuySharedCMSFAQ_buyPage"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedFAQ_itemSearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedWordpressArticles_itemSearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedMobileLinksBlock_itemSearch"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InternalBuyLinkType",
          "kind": "LinkedField",
          "name": "internalBuyLinks",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SharedMobileLinksBlock_links"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuyMobileRefineMenu_buyPage"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbMobileAppliedFilters_itemSearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedSaveSearchContext_itemSearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedFollowSearchBanner_itemSearch"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuyMobileCategoryTiles_itemSearch"
        },
        {
          "args": (v1/*: any*/),
          "kind": "FragmentSpread",
          "name": "BuySharedPersonalizedSearchItems_itemSearch"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRecentSales_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedMobileLinksBlock_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BuySharedResultContainer_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BuyMobileRefineMenu_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BuySharedPersonalizedSearchItems_viewer"
    },
    {
      "condition": "fetchUser",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BuyMobileRefineMenu_user"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SbMobileAppliedFilters_user"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BuySharedResultContainer_user"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SharedRecentSales_user"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SharedYMAL_user"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SharedSaveSearchContext_user"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SharedFollowSearchBanner_user"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BuySharedPersonalizedSearchItems_user"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "580a829714d8b86e956317f8472aec8b";

export default node;
