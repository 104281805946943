import { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

// components
import { MobileModal } from '../../global/MobileModal/MobileModal';
import { sbMobileRefineMenuFiltersMap } from '../../finding/SbMobileRefineMenu/SbMobileRefineMenuFilters/sbMobileRefineMenuFiltersMap';
import { SbSharedRefineMenuFilters } from '../../finding/SbSharedRefineMenu/SbSharedRefineMenuFilters/SbSharedRefineMenuFilters';
import { SbMobileRefineMenuSortBy } from '../../finding/SbMobileRefineMenu/SbMobileRefineMenuSortBy/SbMobileRefineMenuSortBy';
import { SbMobileRefineMenuHeader } from '../../finding/SbMobileRefineMenu/SbMobileRefineMenuHeader/SbMobileRefineMenuHeader';
import { SbSharedRefineMenuSearchWithin } from '../../finding/SbSharedRefineMenu/SbSharedRefineMenuSearchWithin/SbSharedRefineMenuSearchWithin';
import { SbSharedRefineMenuDisplayPrefsSelect } from '../../finding/SbSharedRefineMenu/SbSharedRefineMenuDisplayPrefsSelect/SbSharedRefineMenuDisplayPrefsSelect';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import Filter from 'dibs-icons/exports/legacy/Filter';
import { getValidSharedAppliedFilters } from '../../finding/SbSharedAppliedFilters/sbSharedAppliedFiltersHelpers';

// actions
import { toggleFilterDisplay } from '../../actions/filterActions';

// helpers
import { trackEvent, eventNameConstants } from 'dibs-tracking';
import { useIntl } from 'dibs-react-intl';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import get from 'lodash.get';
import { getQueryParam } from '../../utils/uriUtils';
import { CATEGORY_LEVEL_1 } from '../../utils/categoryHelpers';
import { trackSearchWithinResults } from '../../utils/tracking/searchBrowse/filterTracking';
import { FILTER_REFINE_SEARCH } from '../../finding/SbSharedRefineMenu/sbSharedRefineMenuConstants';

// styles
import styles from '../../finding/SbMobileRefineMenu/SbMobileRefineMenu.scss';
import sbMobileSortRefineStyles from '../../finding/SbMobileSortRefine/SbMobileSortRefine.scss';
import { SbMobileSortRefineMessages as messages } from '../../finding/SbMobileSortRefine/SbMobileSortRefineMessages';

const initiallyExpandedFilters = [CATEGORY_LEVEL_1];
function BuyMobileRefineMenuComponent(props) {
    const {
        user,
        shown,
        viewer,
        filters,
        buyPage: itemSearch,
        currency,
        uriRef,
        isClient,
        closeFilterDisplay,
        openFilterDisplay,
        hasFilterUpdates,
    } = props;
    const appliedSearchFilter = itemSearch.appliedFilters?.find(
        filter => filter?.name === FILTER_REFINE_SEARCH
    );
    const showSearchFilter = appliedSearchFilter ? appliedSearchFilter.canBeDismissed : true;

    const intl = useIntl();
    const topSentinelRef = useRef(null);
    const wrapperRef = useRef(null);
    const prevScroll = useRef(0);

    const [isScrollUp, setIsScrollUp] = useState(false);
    const [isHeaderSticking, setIsHeaderSticking] = useState(false);

    const { totalResults, appliedFilters = [] } = itemSearch;
    const filtersCount = getValidSharedAppliedFilters(appliedFilters).reduce(
        (prev, next) => next.values.length + prev,
        0
    );
    const refinedResultsCountText = filtersCount ? ` (${filtersCount})` : '';

    const handleRefineMenuClose = useCallback(() => {
        closeFilterDisplay();
    }, [closeFilterDisplay]);

    function handleRefineClick(e) {
        const action = 'refine link clicked';
        const label = 'buy page';
        trackEvent(
            {
                action,
                interaction_type: action,
                category: 'results page facet selected',
                eventName: eventNameConstants.EVENT_RESULTS_PAGE_FACET_SELECTED,
                label,
                step_interaction_name: label,
            },
            e
        );
        openFilterDisplay();
    }

    const scrollListener = useCallback(
        debounce(() => {
            const scrollY = window.scrollY;
            if (scrollY === prevScroll.current) {
                return;
            } else {
                setIsScrollUp(scrollY > prevScroll.current);
            }
            prevScroll.current = scrollY;
        }, 55),
        [isScrollUp, setIsScrollUp, prevScroll]
    );

    useEffect(() => {
        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    }, [scrollListener]);

    useEffect(() => {
        if (getQueryParam(get(global, 'location.search'), 'modal') === 'refine') {
            openFilterDisplay();
        }
    }, [openFilterDisplay]);

    return (
        <>
            <MobileModal
                isOpen={shown}
                handleCloseClick={handleRefineMenuClose}
                usePortal={false}
                hideCloseButton
            >
                <>
                    <SbMobileRefineMenuHeader
                        totalResultsCount={totalResults}
                        hideRefineMenu={handleRefineMenuClose}
                        hasFilterUpdates={hasFilterUpdates}
                    />
                    <div className={styles.searchBar}>
                        {showSearchFilter && (
                            <SbSharedRefineMenuSearchWithin
                                onSubmit={trackSearchWithinResults}
                                itemSearch={itemSearch}
                                hideResultsCount
                            />
                        )}
                    </div>
                </>
                <div className={styles.separator} />
                <SbMobileRefineMenuSortBy itemSearch={itemSearch} />
                <SbSharedRefineMenuFilters
                    user={user}
                    viewer={viewer}
                    filters={filters}
                    currency={currency}
                    itemSearch={itemSearch}
                    filterMap={sbMobileRefineMenuFiltersMap}
                    initiallyExpanded={filterName => initiallyExpandedFilters.includes(filterName)}
                    uriRef={uriRef}
                    isClient={isClient}
                />
                <SbSharedRefineMenuDisplayPrefsSelect
                    user={user}
                    filters={filters}
                    itemSearch={itemSearch}
                    isClient={isClient}
                />
            </MobileModal>
            <>
                <div className={styles.topSentinel} ref={topSentinelRef} />
                <VisibilityTracker
                    elementRef={topSentinelRef}
                    onVisibilityChange={({ isVisible }) => setIsHeaderSticking(!isVisible)}
                    watchAfterFirstVisible
                />
                <div
                    className={classnames(sbMobileSortRefineStyles.wrapper, {
                        [sbMobileSortRefineStyles.stickyMode]: isHeaderSticking,
                        [sbMobileSortRefineStyles.hideMode]: isScrollUp,
                    })}
                    onClick={() => {
                        if (isHeaderSticking) {
                            const searchResults = document.querySelector(
                                "div[data-tn='search-results-container']"
                            );
                            const header = document.getElementById('js-header');
                            window.scroll({
                                top:
                                    get(searchResults, 'offsetTop', 0) + // distance search results is from top of container
                                    get(header, 'clientHeight', 0) - // account for the height of the mobile nav
                                    get(wrapperRef, 'current.clientHeight', 0), // as well as the height of the filter/results header (to keep it in view)
                                behavior: 'smooth',
                            });
                        }
                    }}
                    ref={wrapperRef}
                >
                    <div className={sbMobileSortRefineStyles.resultsCount}>
                        {intl.formatMessage(messages.totalResults, {
                            resultCount: totalResults || 0,
                        })}
                    </div>
                    <div className={sbMobileSortRefineStyles.cell}>
                        <button
                            className={sbMobileSortRefineStyles.button}
                            onClick={handleRefineClick}
                            data-tn="refine"
                        >
                            <Filter className={sbMobileSortRefineStyles.filterIcon} />
                            {`${intl.formatMessage(
                                messages.sortAndRefine
                            )}${refinedResultsCountText}`}
                        </button>
                    </div>
                </div>
            </>
        </>
    );
}

BuyMobileRefineMenuComponent.propTypes = {
    isClient: PropTypes.bool,
    viewer: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    buyPage: PropTypes.object.isRequired,
    user: PropTypes.object,
    uriRef: PropTypes.string,
    currency: PropTypes.string.isRequired,

    // redux
    openFilterDisplay: PropTypes.func.isRequired,
    closeFilterDisplay: PropTypes.func.isRequired,
    shown: PropTypes.bool.isRequired,
    hasFilterUpdates: PropTypes.bool.isRequired,
};

BuyMobileRefineMenuComponent = connect(
    ({ filters }) => ({
        shown: filters.filtersShown,
        hasFilterUpdates: filters.hasNewFilterUpdates || false,
    }),
    dispatch => ({
        closeFilterDisplay: () => dispatch(toggleFilterDisplay(false)),
        openFilterDisplay: () => dispatch(toggleFilterDisplay(true)),
    })
)(BuyMobileRefineMenuComponent);

export const BuyMobileRefineMenu = createFragmentContainer(BuyMobileRefineMenuComponent, {
    viewer: graphql`
        fragment BuyMobileRefineMenu_viewer on Viewer {
            ...SbSharedRefineMenuFilters_viewer
        }
    `,
    buyPage: graphql`
        fragment BuyMobileRefineMenu_buyPage on ItemSearchQueryConnection {
            totalResults
            appliedFilters {
                name
                values {
                    urlLabel
                }
                canBeDismissed
            }
            pageType
            ...SbSharedRefineMenuFilters_itemSearch
            ...SbSharedRefineMenuDisplayPrefsSelect_itemSearch
            ...SbSharedRefineMenuSearchWithin_itemSearch
            ...SbMobileRefineMenuSortBy_itemSearch
        }
    `,
    filters: graphql`
        fragment BuyMobileRefineMenu_filters on SearchBrowseFilter @relay(plural: true) {
            ...SbSharedRefineMenuFilters_filters
            ...SbSharedRefineMenuDisplayPrefsSelect_filters
        }
    `,
    user: graphql`
        fragment BuyMobileRefineMenu_user on User {
            ...SbSharedRefineMenuFilters_user
            ...SbSharedRefineMenuDisplayPrefsSelect_user
        }
    `,
});
